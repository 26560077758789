import { render, staticRenderFns } from "./azimut.vue?vue&type=template&id=13fb0929&scoped=true"
import script from "./azimut.vue?vue&type=script&lang=js"
export * from "./azimut.vue?vue&type=script&lang=js"
import style0 from "./azimut.vue?vue&type=style&index=0&id=13fb0929&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13fb0929",
  null
  
)

export default component.exports