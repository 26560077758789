<template>
    <footer class="mobilitysquare-footer footer fixed-bottom" style="z-index: -1">
        <div class="container-fluid d-flex flex-wrap justify-content-between small">
            <a href="http://mobilitysquare.eu" target="_blank" class="d-flex">
                <img :src="footer" :alt="footer_alt" style="height: 80px"  class="manchette"/>            
            </a>
            
            <div class="copyright d-flex flex-wrap">
                Un prodotto di <a href="https://mobilitysquare.eu" target="_blank">MobilitySquare</a> |  
                versione: {{ version }} | 
                <router-link to="/privacy">Privacy Policy</router-link> | 
                Partiva IVA: 03642740041
            </div>
        </div>
    </footer>
</template>
<script>
export default {
	name: "Footer",
	data: function () {
		return {
			footer: process.env.VUE_APP_FOOTER,
			footer_alt: process.env.VUE_APP_FOOTERALT,
			publicPath: process.env.BASE_URL,
			help_mail: process.env.VUE_APP_HELP_MAIL,
			version: process.env.VUE_APP_GIT_TAG,
		};
	},
};
</script>
<style></style>
