<template>
    <div>
        <h2>Scegli l'operatore di trasporto</h2>
         <b-overlay :show="isBusy" rounded="sm">
            <b-card-group deck v-if="!isBusy">
                <b-card
                    v-for="operatore in operatori"
                    :key="operatore.title"
                    :title="operatore.title"
                    :img-src="`${apiPath}/abbonamenti/${operatore.img}`"
                    :img-alt="operatore.title"
                    img-top
                    tag="article"
                    style="max-width: 15rem"
                    class="mb-2">
                    <b-card-text>
                        {{ operatore.text }}
                    </b-card-text>

                    <template #footer>
                        <b-button v-if="operatore.next != ''" href="#" variant="primary" class="float-right mb-1" @click="nextTab(operatore.next)">Scegli</b-button>
                        <b-button v-if="operatore.next == ''" href="#" variant="primary" class="float-right mb-1" disabled>In Arrivo</b-button>
                    </template>
                </b-card>
            </b-card-group>
            <h4>Riepilogo Richieste</h4>
            <router-link to="/abbonamenti/riepilogo">
                <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
                Vai al Riepilogo delle tue richieste
            </router-link>
        </b-overlay>
    </div>
</template>

<script>
import Util from "@/mixing/util";
import operatori from "../data/operatori";
import UserService from "@/services/user.service";

export default {
	name: "Abbonamenti",
	mixins: [Util],
	data() {
        return {
            apiPath: process.env.VUE_APP_ROOT,
            operatori: [],
            isBusy: false,
        };
	},
	computed: {

    },
	methods: {
		nextTab(next) {
			this.$router.push(`/abbonamenti/${next}`);
		},
	},
    async created() {
        this.isBusy = true;
        // leggo l'azienda dell'utente corrente
        const company_id = this.getUserCompany;
        this.operatori = operatori;
        console.log("company_id", company_id);

            if (company_id) {
                // cerco in additional_data l'array tpl_operators --> operatori_attivi
                const company = await UserService.getCompany(company_id);  
                console.log("company", company.data);

                // tengo nell'array operatori solo quelli che sono anche attivi
                //Trasformo la stringa separata da virgole in array 
                let operatori_attivi = [];
                if (company.data.company.additional_data.tpl_operators) {               
                   operatori_attivi = company.data.company.additional_data.tpl_operators.split(/[ ,]+/);
                   this.operatori = this.operatori.filter((operatore) => {
                    return operatori_attivi.includes(operatore.next)
                   });
                }            
        }
        this.isBusy = false;
    },
};
</script>

<style>
.card-img-top {
    padding: 1em;
}
</style>
