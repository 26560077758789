import tipo_documento from "./data/tipo-documento";
import comuni from "./data/comuni-mi";
import mesi from "./data/mesi";
import AbbonamentiService from "./abbonamenti.service";


export default {
	data() {
		return {
			activeTab: 0,
			loading: false,

			validita: [],
			tipo_documento: tipo_documento,

			abbonamento_calcolato: null,
			tariffe: [],
			prezzo_tessera: 5,			
			
			comuni: comuni,
			perc_carico_utente: 1,

			form: {
				origine: null, //queste variabili devono comparire nel v-model del form
				destinazione: null, //aggiungi nome_campo:valore, per ogni campo che crei
				mese_validita: null,
				abbonamento_selezionato: null,
				nome: null,
				cognome: null,
				codice_fiscale: null,
				cittadinanza: null,
				fototessera: null,
				comune_nascita: null,
				provincia_nascita: null,
				numero_tessera: null,
				documento_ID: null,
				nr_documento_ID: null,
				residenza: null,
				email: null,
				telefono: null,
				matricola: null,
				privacy: false,
				tessera_tpl: null,
				operatore: null,
				sesso: "",
				prezzo_pieno: 0,
				prezzo_scontato: 0,		
				abbonamento_tariff_id: null,
				account_trenord: false,				
				autorizzo_trenord: false,				
				date_autorizzo_trenord: new Date().toISOString(),
				tessera_io_viaggio_mobility: null,			
				numero_ioviaggio_mobility: null,	
				codice_cliente_trenord: null,
				trenord_user: null,
				trenord_pwd: null,		
			},
		};
	},
	computed: {
		prezzo_abbonamento: function () {
			if (typeof this.tariffe[this.form.abbonamento_selezionato] === "undefined") {
				return 0;
			} else {
				return parseFloat(this.tariffe[this.form.abbonamento_selezionato].price);
			}
		},
		prezzo_lavoratore: function () {
			if (typeof this.tariffe[this.form.abbonamento_selezionato] === "undefined") {
				return 0;
			} else {
				return parseFloat(this.tariffe[this.form.abbonamento_selezionato].worker_cost);
			}
		},
		prezzo_pieno: function () {
			return parseFloat(this.prezzo_abbonamento);
		},
		prezzo_scontato: function () {
			//Se c'è lo sconto uso quello, altrimenti uso il prezzo lavoratore
			if (this.perc_carico_utente < 1) {
				return parseFloat(this.prezzo_pieno * this.perc_carico_utente);				
			}
			if (this.prezzo_lavoratore > 0){
				return this.prezzo_lavoratore;
			}
			//Se non c'è un prezzo specifico uso il prezzo pieno
			return this.prezzo_pieno;			
		},
		mesi_futuri: function () {
			var d = new Date();
			var m = d.getMonth();
			var y = d.getYear() + 1900;
			var result = [];
			for (let i = 0; i < 6; i++) {
				if (m + i == 12) {
					y++;
				}
				result.push(mesi[(m + i) % 12] + " " + y);
			}
			return result;
		},
		scegli_prossimo: function () {			
			if (this.validita == null || this.validita.length == 0) {
				return {
					validita_abbonamento: 0,
					ordina_entro_il_gg: 0,
					ordina_entro_il_mm: 0,
				};
			}
			let d = new Date();
			let m = d.getMonth() + 1;
			let g = d.getDate();

			let x = this.validita.find((x) => {
				let n = m + 1;
				if (n > 12) {
					n = 1;
				}
				return (m == x.ordina_entro_il_mm && g <= x.ordina_entro_il_gg) || (n == x.ordina_entro_il_mm);
			});

			return x;
		},
		prossimo_to_YMD: function () {
			return `${this.num_to_anno(this.scegli_prossimo.validita_abbonamento)}-${this.scegli_prossimo.validita_abbonamento.toString().padStart(2, "0")}-01`;
		},
	},
	methods: {
		async fetchTplOperator(operatore) {
			this.form.operatore = operatore;
			let res = await AbbonamentiService.getTariffe(this.form.operatore);
			this.tariffe = res.data.tariffe;
			this.validita = this.convertValidita(res.data.validita);
			this.perc_carico_utente = res.data.perc_carico_utente;		
			
			this.ordina_mesi();
			this.form.mese_validita = this.prossimo_to_YMD;		
		},
		submit() {
			this.loading = true;
			this.form.prezzo_pieno = this.prezzo_pieno;
			this.form.prezzo_scontato = this.prezzo_scontato;
			if (this.abbonamento_tariff_id != undefined){
				this.form.abbonamento_tariff_id = this.abbonamento_tariff_id;
			}			

			if (this.form.stato_privacy != "accettato") {
				alert("E' necessario accettare la privacy policy per procedere");
				this.loading = false;
				return;
			}

			AbbonamentiService.saveSportello(this.form).then(
				() => {
					this.$bvModal.msgBoxOk("Abbiamo ricevuto la tua richiesta, puoi controllare l'avanzamento nella pagina del riepilogo").then(() => {
						this.loading = false;
						this.$router.push("riepilogo");
					});
				},
				(error) => {
					this.showError(error);
					this.loading = false;
				}
			);
			this.loading = false;
		},
		nextTab() {			
			let t = this.activeTab +1;
			let f=`form-${t}`;            			
			if (this.$refs[f].checkValidity()) {
				this.activeTab++;
			}
			else {	
				this.$refs[f].reportValidity();              	
			}
		},
		notYet() {
			this.$bvModal.msgBoxOk("Questo servizio non è ancora integrato nella piattaforma. Ci stiamo lavorando");
		},
		num_to_mese(x) {
			return mesi[x];
		},
		num_to_anno(x) {
			var d = new Date();
			var m = d.getMonth() + 1;
			var y = d.getYear() + 1900;
			//Se ho già superato il mese da scrivere lo considero nel prossimo anno
			if (m > x) {
				y++;
			}
			return y;
		},
		ordina_mesi() {
			var d = new Date();
			var m = d.getMonth() + 1;

			this.validita.map(x => {
				if (x.ordina_entro_il_mm < m) {
					x.order = x.ordina_entro_il_mm + 12 - m;
				} else {
					x.order = x.ordina_entro_il_mm - m;
				}

				return x;
			});
			this.validita.sort(function(a, b) {
				return a.order - b.order;
			});
		},
		onReset(evt) {
			evt.preventDefault();
			// Reset our form values
			//this.form.email = ''
			//this.form.name = ''
		},

		//Converte l'oggetto restituito dal backend in un'array di oggetti
		//Massimoi - 2024-02-10 (forse c'è ui modo migliore di farlo, ma non mi viene in mente)
		convertValidita(v) {
			let r = [];
			Object.entries(v).forEach(([key, value]) => {				
				r.push({
					validita_abbonamento: parseInt(key),
					ordina_entro_il_gg: parseInt(value.ordina_entro_il_gg),
					ordina_entro_il_mm: parseInt(value.ordina_entro_il_mm),				
				});
			});
			return r;
		}
	}
};
