import httpClient from "@/services/httpClient";

class AbbonamentiService {
	saveSportello(form) {
		var myForm = new FormData();
		let keysArray = Object.keys(form);
		for (let k of keysArray) {
			if (k !== "fototessera") {
				myForm.set(k, form[k]);
			}
		}
		//Il file
		myForm.append("fototessera", form.fototessera);

		return httpClient({
			method: "post",
			url: "/subscriptions/subscriptions/add.json",
			data: myForm,
			headers: {
				"Content-Type": "multipart/form-data"         	}	
		});
	}

	async getRiepilogoAbbonamenti() {
		return httpClient.get("/subscriptions/subscriptions/riepilogo.json");
	}

	async updateTos() {
		return httpClient.get("/users/update-tos.json");
	}

	async trenordLogin(username, password) {
		let url = "/subscriptions/subscriptions/trenord.json";
		return httpClient.post(url, { username, password });
	}

	async trenordAccountExists() {
		return httpClient.get("/subscriptions/subscriptions/trenord.json");
	}

	async getTariffe(operator_slug) {
		return httpClient.get(`/subscriptions/tpl-operators/tariffe/${operator_slug}.json`);
	}

	async registerSportello(email, company_id, matricola) {
		var myForm = new FormData();
		myForm.append("email", email);
		myForm.append("company_id", company_id);
		myForm.append("badge_number", matricola);

		return httpClient({
			method: "post",
			url: "/subscriptions/users/register.json",
			data: myForm,
		});
	}

}

export default new AbbonamentiService();
